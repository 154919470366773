import React, {useState} from 'react'
import {shallowEqual, useSelector, useDispatch} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {MenuTwo} from "../components/MenuTwo";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const CustomerListStudy = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      {currentUser.user.level <= 1 && <>
        <div style={{ backgroundColor: '#333', height: '100%'}}>
          <div
            style={{
              width: WIDTH,
              height: '100%',
              margin: 'auto',
              backgroundColor: '#bbeafe'
            }}
          >
            <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                 src={toAbsoluteUrl('/media/logos/study-lv1.jpg')}
                 alt=""
            />
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <a href="https://www.robotanan.com/"  target='_blank'>
                <button
                  type="button"
                  style={{
                    width: '70%',
                    padding: '15px',
                    backgroundColor: '#0dc735',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    borderRadius: '15px',
                    borderWidth: '0px',
                    fontFamily: 'Linotte-Heavy',
                  }}
                >
            <span
              style={{
                fontSize: '24px',
                fontFamily: 'Linotte-Heavy',
                color: '#ffffff',
                letterSpacing: 2.0
              }}
            >
              TÌM HIỂU THÊM
            </span>
                </button>
              </a>
            </div>

            <MenuTwo active='study'/>
          </div>

        </div>
      </>}

      {currentUser.user.level >= 2 && <>
        <div style={{ backgroundColor: '#333', height: '100%'}}>
          <div
            style={{
              width: WIDTH,
              height: '100%',
              margin: 'auto',
              backgroundColor: '#fedac0'
            }}
          >
            <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                 src={toAbsoluteUrl('/media/logos/list-study-0.jpg')}
                 alt=""
            />
            <a href="https://www.youtube.com/watch?v=XNlQTVDHNlg"  target='_blank'>
              <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                   src={toAbsoluteUrl('/media/logos/huong-dan.jpg')}
                   alt=""
              />
            </a>
            <Link to='/customer/study'>
              <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                   src={toAbsoluteUrl('/media/logos/list-study-1.jpg')}
                   alt=""
              />
            </Link>
            <Link to='/customer/study-think'>
              <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                   src={toAbsoluteUrl('/media/logos/list-study-2.jpg')}
                   alt=""
              />
            </Link>
            <img style={{ width: '100%', height: 'auto', padding: '0px', borderRadius: '0px' }}
                 src={toAbsoluteUrl('/media/logos/list-study-3.jpg')}
                 alt=""
            />

            <MenuTwo active='study'/>
          </div>

        </div>
      </>}
    </>
  )

}

export {CustomerListStudy}
