/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {RootState} from "../../setup";

const CustomerInfo = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language
  const isActive = currentUser.user.is_active;
  const isScan = currentUser.user.is_scan;
  const isChild = currentUser.user.is_child;

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100px',
            paddingTop: '30px',
            paddingLeft: '20px',
            backgroundColor: '#a14ad9',
            paddingRight: '20px',
            justifyContent: 'space-between',
            display: 'flex'
          }}
        >
          <h1 style={{
            color: 'white',
            fontFamily: 'Linotte-Heavy',
            fontSize: '24px'
          }}>
            {LANGUAGE === 'VI'
              ? 'Người dùng'
              : 'User'
            }
          </h1>
          <div>
            <h1
              onClick={() => dispatch({
                type: 'SET_LANGUAGE',
                payload: {
                  language: 'VI'
                }
              })}
              style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
              VIE
            </h1>
            <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
              |
            </h1>
            <h1
              onClick={() => dispatch({
                type: 'SET_LANGUAGE',
                payload: {
                  language: 'EN'
                }
              })}
              style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
              ENG
            </h1>
          </div>
        </div>

        <div
          style={{
            marginTop: '-50px',
            textAlign: 'center'
          }}
        >
          {isActive && isScan
            ?
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                src={isChild ? currentUser.user.character.main_image_link : currentUser.user.character_adult.main_image_link} alt=""/>
            :
            <img style={{width: '100%', height: 'auto', padding: '15px', borderRadius: '30px'}}
                 src={toAbsoluteUrl('/media/logos/inactive-user.jpg')} alt=""/>
          }
          <h2 style={{ marginTop: '-40px', color: '#fff' }}>{currentUser.user.name}</h2>
          <div style={{ padding: '10px 40px', textAlign: 'center' }}>
            <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >
              {LANGUAGE === 'VI'
                ? 'Số điện thoại: '
                : 'Phone number: '
              }
              {currentUser.user.phone}
            </h4>
            {isActive ? <h4 style={{ color: '#01a1ff', lineHeight: '1.8' }} >
              {LANGUAGE === 'VI'
                ? 'Mã quét mặt: '
                : 'Face scanning code: '
              }
              {currentUser.user.scan_code}
            </h4> : <></>}
            <h4 style={{ color: 'red', lineHeight: '1.6', fontFamily: 'Linotte-Regular' }} >
              {LANGUAGE === 'VI'
                ? '*Bạn hãy lưu lại mã quét mặt để thuận tiện trong việc thay đổi hoặc tìm lại mật khẩu'
                : '*Please save the face scan code for convenience in changing or finding your password'
              }
            </h4>
          </div>
        </div>

        <Link to={{
          pathname: '/customer/info-change-password',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi mật khẩu'
                  : 'Change Password'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-name',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi tên'
                  : 'Change Name'
                }
              </h4>
            </div>
          </div>
        </Link>

        <div style={{ padding: '10px' }}>
          <div
            onClick={() => dispatch(auth.actions.logout())}
            style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
            <h4 style={{ color: '#fff' }}>
              {LANGUAGE === 'VI'
                ? 'Đăng xuất'
                : 'Log out'
              }
            </h4>
          </div>
        </div>


        {/*height menu*/}
        <div style={{height: '70px'}}></div>
        {/*menu*/}
        <div style={{
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: WIDTH,
          height: '70px',
          margin: 'auto',
          zIndex: '1'
        }}>
          <div style={{
            width: '25%',
            height: '100%',
            backgroundColor: '#fff',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '10px'
          }}>
            <Link to='/customer/report-image'>
              <div style={{padding: '0px 13px'}}>
                <div style={{}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={{
            width: '25%',
            height: '100%',
            backgroundColor: '#fff',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '10px'
          }}>
            <Link to='/customer/list-study'>
              <div style={{padding: '0px 13px'}}>
                <div style={{}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={{
            width: '25%',
            height: '100%',
            backgroundColor: '#fff',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '10px'
          }}>
            <Link to='/customer/intro'>
              <div style={{padding: '0px 13px'}}>
                <div style={{}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/3-gioi-thieu.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={{
            width: '25%',
            height: '100%',
            backgroundColor: '#fff',
            display: 'inline-block',
            textAlign: 'center',
            paddingTop: '10px'
          }}>
            <Link to='/customer/info'>
              <div style={{padding: '0px 13px'}}>
                <div style={{border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

    </div>
  )
}

export {CustomerInfo}

