/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState, useContext} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useLocation} from "react-router-dom";
import {MenuTwo} from '../components/MenuTwo'
import {FullReport} from "../pages/FullReport";
import {Accordion, useAccordionToggle, AccordionContext} from 'react-bootstrap-v5';
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const Map = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const characterId = currentUser.user.character_id;
  const minorCharacterId = currentUser.user.minor_character_id;
  const roadCharacterId = currentUser.user.road_character_id;
  const spiritLevel = currentUser.user.spirit_level;
  const isFirst = currentUser.user.is_first;
  const [showButton, setShowButton] = useState(true);
  const reportVideo = useRef()
  const [errorMessage, setErrorMessage] = useState('');
  let me = null;
  let father = null;
  let mother = null;
  let childOne = null;
  let childTwo = null;
  let childThree = null;
  let childFour = null;
  let childFive = null;
  let family = currentUser.user.family ?? null
  if (family) {
    family.forEach((member) => {
      if (member.id === currentUser.user.id) {
        me = member
      } else {
        switch (member.family_role) {
          case 'FATHER':
            father = member
            break
          case 'MOTHER':
            mother = member
            break
          case 'CHILD_ONE':
            childOne = member
            break
          case 'CHILD_TWO':
            childTwo = member
            break
          case 'CHILD_THREE':
            childThree = member
            break
          case 'CHILD_FOUR':
            childFour = member
            break
          case 'CHILD_FIVE':
            childFive = member
            break
        }
      }
    })
  }
  console.log({me, father, mother, childOne, childTwo, childThree, childFour, childFive})

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  function CustomToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <div
        style={{
          // backgroundColor: isCurrentEventKey ? '#55db37' : '#fff',
          padding: '20px'
        }}
        onClick={decoratedOnClick}
      >
        {children}
      </div>
    );
  }

  return (
    <>
      {isActive && isScan
        ?
          <>
            {currentUser.user.level !== 3 && <>
              <div style={{ backgroundColor: '#333', height: '100%'}}>
                <div
                  style={{
                    width: WIDTH,
                    height: '100%',
                    margin: 'auto',
                    backgroundColor: '#333333'
                  }}
                >

                  <div
                    style={{
                      width: WIDTH,
                      backgroundColor: '#fff',
                      marginBottom: '20px'
                    }}
                  >
                    <img style={{ width: WIDTH, height: 'auto' }} src={toAbsoluteUrl('/media/character/upgrade.jpg')} alt=""/>
                    <div
                      style={{
                        borderRadius: '15px',
                        padding: '20px'
                      }}
                    >
                      <div>
                        <div
                          style={{
                            width: '100%',
                            display: 'inline-block'
                          }}
                        >
                          <p
                            style={{ fontFamily: 'Linotte-Heavy' }}
                            className='fs-1 text-center'>THÔNG TIN CHUYỂN KHOẢN</p>

                          <div
                            style={{
                              borderBottom: '1px dashed #b0b0b0',
                              margin: '5px',
                              padding: '10px 10px 10px 0px'
                            }}
                          >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Ngân hàng: '}
                              </span>
                            <span
                              style={{
                                color: '#fd1a1c',
                                fontSize: '20px',
                                fontFamily: 'Linotte-Heavy'
                              }}
                            >
                                Quân đội MBBank
                              </span>
                          </div>

                          <div
                            style={{
                              borderBottom: '1px dashed #b0b0b0',
                              margin: '5px',
                              padding: '10px 10px 10px 0px'
                            }}
                          >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tài khoản: '}
                              </span>
                            <span
                              style={{
                                color: '#fd1a1c',
                                fontSize: '20px',
                                fontFamily: 'Linotte-Heavy'
                              }}
                            >
                                1988288288
                              </span>
                            <button className='btn btn-success btn-sm ms-4'
                                    style={{ marginTop: '-10px' }}
                                    onClick={() => navigator.clipboard.writeText('1988288288')}
                            >
                              Copy
                            </button>
                          </div>

                          <div
                            style={{
                              borderBottom: '1px dashed #b0b0b0',
                              margin: '5px',
                              padding: '10px 10px 10px 0px'
                            }}
                          >
                            <span
                              style={{
                                color: '#333',
                                fontSize: '20px',
                                fontFamily: 'Linotte-Regular'
                              }}
                            >
                              {'Chủ tài khoản: '}
                            </span>
                            <span
                              style={{
                                color: '#fd1a1c',
                                fontSize: '20px',
                                fontFamily: 'Linotte-Heavy'
                              }}
                            >
                              Trần Ngọc Thiện
                              <br/>
                              (nhà sáng lập dự án)
                            </span>
                          </div>


                          <div
                            style={{
                              borderBottom: '1px dashed #b0b0b0',
                              margin: '5px',
                              padding: '10px 10px 10px 0px'
                            }}
                          >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tiền chuyển khoản: '}
                              </span>
                            <span
                              style={{
                                color: '#fd1a1c',
                                fontSize: '20px',
                                fontFamily: 'Linotte-Heavy'
                              }}
                            >
                                100.000 đ
                              </span>
                          </div>

                          <div
                            style={{
                              borderBottom: '1px dashed #b0b0b0',
                              margin: '5px',
                              padding: '10px 10px 10px 0px'
                            }}
                          >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Nội dung: '}
                              </span>
                            <span
                              style={{
                                color: '#fd1a1c',
                                fontSize: '20px',
                                fontFamily: 'Linotte-Heavy'
                              }}
                            >
                                  {'Anbi ' + currentUser.user.phone}
                                </span>
                            <button className='btn btn-success btn-sm ms-4'
                                    style={{ marginTop: '-10px' }}
                                    onClick={() => navigator.clipboard.writeText('Anbi ' + currentUser.user.phone)}
                            >
                              Copy
                            </button>
                          </div>

                          <div
                            style={{
                              margin: '5px',
                              padding: '10px 10px 10px 0px',
                              textAlign: 'center'
                            }}
                          >
                            <span
                              style={{
                                color: '#333',
                                fontSize: '22px',
                                fontFamily: 'Linotte-Regular'
                              }}
                            >
                              {'Mã QR: '}
                            </span>
                            <br/>
                            <div className='text-center'>
                              <img style={{ width: '90%', borderRadius: '5px', marginTop: '10px' }} src={toAbsoluteUrl('/media/logos/qr-tai-tro.jpg')} alt=""/>
                            </div>
                          </div>

                          <p
                            style={{
                              color: '#000',
                              fontSize: '20px',
                              fontFamily: 'Linotte-Regular',
                              textAlign: 'center'
                            }}
                          >
                            Sau khi chuyển khoản được 60 giây, bạn vui lòng nhấn vào nút “XÁC NHẬN ĐÃ CHUYỂN KHOẢN” bên dưới để nhận bản báo cáo đầy đủ.
                          </p>

                          {errorMessage &&
                          <p
                            style={{
                              color: '#e22929',
                              fontSize: '22px',
                              fontFamily: 'Linotte-Regular',
                              textAlign: 'justify'
                            }}
                          >
                            {errorMessage}
                          </p>
                          }

                          <button
                            type="button"
                            style={{
                              width: '100%',
                              padding: '15px',
                              backgroundColor: '#30a3fc',
                              justifyContent: 'center',
                              textAlign: 'center',
                              alignItems: 'center',
                              borderRadius: '10px',
                              borderWidth: '0px',
                              fontFamily: 'Linotte-Regular',
                              marginTop: '25px'
                            }}
                            onClick={() => {
                              setErrorMessage('')
                              axios.post(`${BACKEND_URL}/confirm-donate`, {
                                phone: currentUser.user.phone,
                              }).then(res => res.data)
                                .then(resData => {
                                  console.log({resData})
                                  let userLevel = resData.data.user.level ?? 0
                                  if (userLevel == 3) {
                                    window.location.replace(window.location.origin);
                                  } else {
                                    setErrorMessage('Hiện tại hệ thống chưa nhận được số tiền chuyển khoản của bạn. Bạn vui lòng chuyển khoản nếu chưa kịp thực hiện. Nếu bạn đã hoàn thành chuyển khoản mà sau 5 phút vẫn chưa xuất hiện bản mô tả tính cách thì xin vui lòng vuốt dọc màn hình để làm mới nội dung, hoặc liên hệ hotline 0967886248 để được hỗ trợ kỹ thuật.')
                                    window.scrollTo(0, document.body.scrollHeight)
                                  }
                                })
                                .catch(err => {
                                  console.log({err})
                                  setErrorMessage('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                                })
                            }}
                          >
                            <span
                              style={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: '#fff',
                                letterSpacing: 1.1
                              }}
                            >
                              XÁC NHẬN ĐÃ CHUYỂN KHOẢN
                            </span>
                          </button>



                        </div>
                      </div>
                    </div>
                  </div>



                  <Link to={{
                    pathname: '/customer/info-change-password',
                    state: { test: "hello" }
                  }}>
                    <div style={{ padding: '10px' }}>
                      <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                        <h4 style={{ color: '#fff' }}>
                          {LANGUAGE === 'VI'
                            ? 'Đổi mật khẩu'
                            : 'Change Password'
                          }
                        </h4>
                      </div>
                    </div>
                  </Link>

                  <Link to={{
                    pathname: '/customer/info-change-name',
                    state: { test: "hello" }
                  }}>
                    <div style={{ padding: '10px' }}>
                      <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                        <h4 style={{ color: '#fff' }}>
                          {LANGUAGE === 'VI'
                            ? 'Đổi tên'
                            : 'Change Name'
                          }
                        </h4>
                      </div>
                    </div>
                  </Link>

                  <div style={{ padding: '10px' }}>
                    <div
                      onClick={() => dispatch(auth.actions.logout())}
                      style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                      <h4 style={{ color: '#fff' }}>
                        {LANGUAGE === 'VI'
                          ? 'Đăng xuất'
                          : 'Log out'
                        }
                      </h4>
                    </div>
                  </div>

                  {/*menu*/}
                  <MenuTwo active='map'/>
                </div>

              </div>
            </>}

            {currentUser.user.level === 3 && <>
              <div style={{ backgroundColor: '#333', height: '100%'}}>
                <div
                  style={{
                    width: WIDTH,
                    height: '100%',
                    margin: 'auto',
                    backgroundColor: '#333333'
                  }}
                >

                  {/*bản dồ giao tiếp start*/}
                  <div
                    style={{
                      margin: '0px 0px 20px 0px',
                      backgroundColor: '#fff',
                      borderRadius: '10px'
                    }}
                  >
                    <p
                      className='text-center pt-7'
                      style={{
                        fontFamily: 'Linotte-Bold',
                        fontSize: '38px',
                        color: '#ee962e',
                      }}
                    >BẢN ĐỒ</p>
                    <p
                      className='text-center mt-n6 mb-1'
                      style={{
                        fontFamily: 'Linotte-Heavy',
                        fontSize: '46px',
                        color: '#0e0ef8',
                      }}
                    >GIAO TIẾP</p>

                    {me && <>
                      <div
                        style={{
                          padding: '20px',
                        }}
                      >
                        <div
                          className='d-flex'
                          style={{
                            padding: '10px',
                            border: '2px solid #f13333',
                            borderRadius: '10px',
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <img style={{ width: '80px', height: '80px' }}
                                 src={toAbsoluteUrl(`/media/character/${me.character_id}.png`)}
                                 alt=""/>
                          </div>
                          <div className='d-flex align-items-center ms-5'>
                            <div>
                              <span
                                style={{
                                  fontFamily: 'Linotte-Regular',
                                  fontSize: '18px',
                                  color: '#2f2f2f',
                                }}
                              >Bạn {' ( ' + me.name + ' )'}</span>
                              <br/>
                              <span
                                style={{
                                  fontFamily: 'Linotte-Bold',
                                  fontSize: '20px',
                                  color: '#47b3d5',
                                }}
                              >
                                {me.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                {me.character_id === 2 && ' THỎ ẤM ÁP'}
                                {me.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                {me.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                {me.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                {me.character_id === 6 && ' SÓI TRUNG THÀNH'}
                                {me.character_id === 7 && ' KHỈ LINH HOẠT'}
                                {me.character_id === 8 && ' HỔ MẠNH MẼ'}
                                {me.character_id === 9 && ' VOI ÔN HÒA'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>}

                    <p
                      className='text-center'
                      style={{
                        fontFamily: 'Linotte-Regular',
                        fontSize: '20px',
                        color: '#969696',
                      }}
                    >Nhấp chọn thành viên để xem <br/> Bản đồ Giao tiếp</p>

                    <Accordion>
                      {father && <>
                        <CustomToggle eventKey="0">
                          <div
                            className='d-flex'
                            style={{
                              padding: '10px',
                              border: '2px solid #ecdbdd',
                              borderRadius: '10px',
                            }}
                          >
                            <div className='d-flex align-items-center'>
                              <img style={{ width: '80px', height: '80px' }}
                                   src={toAbsoluteUrl(`/media/character/${father.character_id}.png`)}
                                   alt=""/>
                            </div>
                            <div className='d-flex align-items-center ms-5'>
                              <div>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Regular',
                                    fontSize: '18px',
                                    color: '#2f2f2f',
                                  }}
                                >{me.family_role === 'MOTHER' ? 'Chồng' : 'Bố'} {' ( ' + father.name + ' )'}</span>
                                <br/>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Bold',
                                    fontSize: '20px',
                                    color: '#47b3d5',
                                  }}
                                >
                                  {father.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {father.character_id === 2 && ' THỎ ẤM ÁP'}
                                  {father.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {father.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {father.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {father.character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {father.character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {father.character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {father.character_id === 9 && ' VOI ÔN HÒA'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="0">
                          <img style={{ width: WIDTH, height: 'auto' }} src={`https://backend.robotanan.com/map/${me.character_id}${father.character_id}.jpg`} alt=""/>
                        </Accordion.Collapse>
                      </>}

                      {mother && <>
                        <CustomToggle eventKey="1">
                          <div
                            className='d-flex'
                            style={{
                              padding: '10px',
                              border: '2px solid #ecdbdd',
                              borderRadius: '10px',
                            }}
                          >
                            <div className='d-flex align-items-center'>
                              <img style={{ width: '80px', height: '80px' }}
                                   src={toAbsoluteUrl(`/media/character/${mother.character_id}.png`)}
                                   alt=""/>
                            </div>
                            <div className='d-flex align-items-center ms-5'>
                              <div>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Regular',
                                    fontSize: '18px',
                                    color: '#2f2f2f',
                                  }}
                                >{me.family_role === 'FATHER' ? 'Vợ' : 'Mẹ'} {' ( ' + mother.name + ' )'}</span>
                                <br/>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Bold',
                                    fontSize: '20px',
                                    color: '#47b3d5',
                                  }}
                                >
                                  {mother.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {mother.character_id === 2 && ' THỎ ẤM ÁP'}
                                  {mother.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {mother.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {mother.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {mother.character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {mother.character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {mother.character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {mother.character_id === 9 && ' VOI ÔN HÒA'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="1">
                          <img style={{ width: WIDTH, height: 'auto' }} src={`https://backend.robotanan.com/map/${me.character_id}${mother.character_id}.jpg`} alt=""/>
                        </Accordion.Collapse>
                      </>}

                      {childOne && <>
                        <CustomToggle eventKey="2">
                          <div
                            className='d-flex'
                            style={{
                              padding: '10px',
                              border: '2px solid #ecdbdd',
                              borderRadius: '10px',
                            }}
                          >
                            <div className='d-flex align-items-center'>
                              <img style={{ width: '80px', height: '80px' }}
                                   src={toAbsoluteUrl(`/media/character/${childOne.character_id}.png`)}
                                   alt=""/>
                            </div>
                            <div className='d-flex align-items-center ms-5'>
                              <div>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Regular',
                                    fontSize: '18px',
                                    color: '#2f2f2f',
                                  }}
                                >
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childOne.gender === 'male') && <span>Con trai thứ nhất</span>}
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childOne.gender === 'female') && <span>Con gái thứ nhất</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childOne.birth_year && childOne.gender === 'male') && <span>Anh trai thứ nhất</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childOne.birth_year && childOne.gender === 'female') && <span>Chị gái thứ nhất</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childOne.birth_year && childOne.gender === 'male') && <span>Em trai thứ nhất</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childOne.birth_year && childOne.gender === 'female') && <span>Em gái thứ nhất</span>}
                                  {' ( ' + childOne.name + ' )'}
                                </span>
                                <br/>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Bold',
                                    fontSize: '20px',
                                    color: '#47b3d5',
                                  }}
                                >
                                  {childOne.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {childOne.character_id === 2 && ' THỎ ẤM ÁP'}
                                  {childOne.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {childOne.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {childOne.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {childOne.character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {childOne.character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {childOne.character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {childOne.character_id === 9 && ' VOI ÔN HÒA'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="2">
                          <img style={{ width: WIDTH, height: 'auto' }} src={`https://backend.robotanan.com/map/${me.character_id}${childOne.character_id}.jpg`} alt=""/>
                        </Accordion.Collapse>
                      </>}

                      {childTwo && <>
                        <CustomToggle eventKey="3">
                          <div
                            className='d-flex'
                            style={{
                              padding: '10px',
                              border: '2px solid #ecdbdd',
                              borderRadius: '10px',
                            }}
                          >
                            <div className='d-flex align-items-center'>
                              <img style={{ width: '80px', height: '80px' }}
                                   src={toAbsoluteUrl(`/media/character/${childTwo.character_id}.png`)}
                                   alt=""/>
                            </div>
                            <div className='d-flex align-items-center ms-5'>
                              <div>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Regular',
                                    fontSize: '18px',
                                    color: '#2f2f2f',
                                  }}
                                >
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childTwo.gender === 'male') && <span>Con trai thứ hai</span>}
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childTwo.gender === 'female') && <span>Con gái thứ hai</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childTwo.birth_year && childTwo.gender === 'male') && <span>Anh trai thứ hai</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childTwo.birth_year && childTwo.gender === 'female') && <span>Chị gái thứ hai</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childTwo.birth_year && childTwo.gender === 'male') && <span>Em trai thứ hai</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childTwo.birth_year && childTwo.gender === 'female') && <span>Em gái thứ hai</span>}
                                  {' ( ' + childTwo.name + ' )'}
                                </span>
                                <br/>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Bold',
                                    fontSize: '20px',
                                    color: '#47b3d5',
                                  }}
                                >
                                  {childTwo.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {childTwo.character_id === 2 && ' THỎ ẤM ÁP'}
                                  {childTwo.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {childTwo.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {childTwo.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {childTwo.character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {childTwo.character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {childTwo.character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {childTwo.character_id === 9 && ' VOI ÔN HÒA'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="3">
                          <img style={{ width: WIDTH, height: 'auto' }} src={`https://backend.robotanan.com/map/${me.character_id}${childTwo.character_id}.jpg`} alt=""/>
                        </Accordion.Collapse>
                      </>}

                      {childThree && <>
                        <CustomToggle eventKey="4">
                          <div
                            className='d-flex'
                            style={{
                              padding: '10px',
                              border: '2px solid #ecdbdd',
                              borderRadius: '10px',
                            }}
                          >
                            <div className='d-flex align-items-center'>
                              <img style={{ width: '80px', height: '80px' }}
                                   src={toAbsoluteUrl(`/media/character/${childThree.character_id}.png`)}
                                   alt=""/>
                            </div>
                            <div className='d-flex align-items-center ms-5'>
                              <div>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Regular',
                                    fontSize: '18px',
                                    color: '#2f2f2f',
                                  }}
                                >
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childThree.gender === 'male') && <span>Con trai thứ ba</span>}
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childThree.gender === 'female') && <span>Con gái thứ ba</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childThree.birth_year && childThree.gender === 'male') && <span>Anh trai thứ ba</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childThree.birth_year && childThree.gender === 'female') && <span>Chị gái thứ ba</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childThree.birth_year && childThree.gender === 'male') && <span>Em trai thứ ba</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childThree.birth_year && childThree.gender === 'female') && <span>Em gái thứ ba</span>}
                                  {' ( ' + childThree.name + ' )'}
                                </span>
                                <br/>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Bold',
                                    fontSize: '20px',
                                    color: '#47b3d5',
                                  }}
                                >
                                  {childThree.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {childThree.character_id === 2 && ' THỎ ẤM ÁP'}
                                  {childThree.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {childThree.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {childThree.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {childThree.character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {childThree.character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {childThree.character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {childThree.character_id === 9 && ' VOI ÔN HÒA'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="4">
                          <img style={{ width: WIDTH, height: 'auto' }} src={`https://backend.robotanan.com/map/${me.character_id}${childThree.character_id}.jpg`} alt=""/>
                        </Accordion.Collapse>
                      </>}

                      {childFour && <>
                        <CustomToggle eventKey="5">
                          <div
                            className='d-flex'
                            style={{
                              padding: '10px',
                              border: '2px solid #ecdbdd',
                              borderRadius: '10px',
                            }}
                          >
                            <div className='d-flex align-items-center'>
                              <img style={{ width: '80px', height: '80px' }}
                                   src={toAbsoluteUrl(`/media/character/${childFour.character_id}.png`)}
                                   alt=""/>
                            </div>
                            <div className='d-flex align-items-center ms-5'>
                              <div>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Regular',
                                    fontSize: '18px',
                                    color: '#2f2f2f',
                                  }}
                                >
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childFour.gender === 'male') && <span>Con trai thứ tư</span>}
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childFour.gender === 'female') && <span>Con gái thứ tư</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childFour.birth_year && childFour.gender === 'male') && <span>Anh trai thứ tư</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childFour.birth_year && childFour.gender === 'female') && <span>Chị gái thứ tư</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childFour.birth_year && childFour.gender === 'male') && <span>Em trai thứ tư</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childFour.birth_year && childFour.gender === 'female') && <span>Em gái thứ tư</span>}
                                  {' ( ' + childFour.name + ' )'}
                                </span>
                                <br/>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Bold',
                                    fontSize: '20px',
                                    color: '#47b3d5',
                                  }}
                                >
                                  {childFour.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {childFour.character_id === 2 && ' THỎ ẤM ÁP'}
                                  {childFour.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {childFour.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {childFour.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {childFour.character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {childFour.character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {childFour.character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {childFour.character_id === 9 && ' VOI ÔN HÒA'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="5">
                          <img style={{ width: WIDTH, height: 'auto' }} src={`https://backend.robotanan.com/map/${me.character_id}${childFour.character_id}.jpg`} alt=""/>
                        </Accordion.Collapse>
                      </>}

                      {childFive && <>
                        <CustomToggle eventKey="6">
                          <div
                            className='d-flex'
                            style={{
                              padding: '10px',
                              border: '2px solid #ecdbdd',
                              borderRadius: '10px',
                            }}
                          >
                            <div className='d-flex align-items-center'>
                              <img style={{ width: '80px', height: '80px' }}
                                   src={toAbsoluteUrl(`/media/character/${childFive.character_id}.png`)}
                                   alt=""/>
                            </div>
                            <div className='d-flex align-items-center ms-5'>
                              <div>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Regular',
                                    fontSize: '18px',
                                    color: '#2f2f2f',
                                  }}
                                >
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childFive.gender === 'male') && <span>Con trai thứ năm</span>}
                                  {((me.family_role === 'FATHER' || me.family_role === 'MOTHER') && childFive.gender === 'female') && <span>Con gái thứ năm</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childFive.birth_year && childFive.gender === 'male') && <span>Anh trai thứ năm</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year >= childFive.birth_year && childFive.gender === 'female') && <span>Chị gái thứ năm</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childFive.birth_year && childFive.gender === 'male') && <span>Em trai thứ năm</span>}
                                  {(me.family_role !== 'FATHER' && me.family_role !== 'MOTHER' && me.birth_year < childFive.birth_year && childFive.gender === 'female') && <span>Em gái thứ năm</span>}
                                  {' ( ' + childFive.name + ' )'}
                                </span>
                                <br/>
                                <span
                                  style={{
                                    fontFamily: 'Linotte-Bold',
                                    fontSize: '20px',
                                    color: '#47b3d5',
                                  }}
                                >
                                  {childFive.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {childFive.character_id === 2 && ' THỎ ẤM ÁP'}
                                  {childFive.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {childFive.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {childFive.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {childFive.character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {childFive.character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {childFive.character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {childFive.character_id === 9 && ' VOI ÔN HÒA'}
                                </span>
                              </div>
                            </div>
                          </div>
                        </CustomToggle>
                        <Accordion.Collapse eventKey="6">
                          <img style={{ width: WIDTH, height: 'auto' }} src={`https://backend.robotanan.com/map/${me.character_id}${childFive.character_id}.jpg`} alt=""/>
                        </Accordion.Collapse>
                      </>}

                    </Accordion>
                  </div>
                  {/*bản dồ giao tiếp end*/}

                  <Link to={{
                    pathname: '/customer/update-family-phone',
                    state: { test: "hello" }
                  }}>
                    <div style={{ padding: '10px' }}>
                      <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                        <h4 style={{ color: '#fff' }}>
                          Cập nhật mã gia đình
                        </h4>
                      </div>
                    </div>
                  </Link>

                  <Link to={{
                    pathname: '/customer/info-change-password',
                    state: { test: "hello" }
                  }}>
                    <div style={{ padding: '10px' }}>
                      <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                        <h4 style={{ color: '#fff' }}>
                          {LANGUAGE === 'VI'
                            ? 'Đổi mật khẩu'
                            : 'Change Password'
                          }
                        </h4>
                      </div>
                    </div>
                  </Link>

                  <Link to={{
                    pathname: '/customer/info-change-name',
                    state: { test: "hello" }
                  }}>
                    <div style={{ padding: '10px' }}>
                      <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                        <h4 style={{ color: '#fff' }}>
                          {LANGUAGE === 'VI'
                            ? 'Đổi tên'
                            : 'Change Name'
                          }
                        </h4>
                      </div>
                    </div>
                  </Link>

                  <div style={{ padding: '10px' }}>
                    <div
                      onClick={() => dispatch(auth.actions.logout())}
                      style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                      <h4 style={{ color: '#fff' }}>
                        {LANGUAGE === 'VI'
                          ? 'Đăng xuất'
                          : 'Log out'
                        }
                      </h4>
                    </div>
                  </div>

                  {/*menu*/}
                  <MenuTwo active='map'/>
                </div>

              </div>
            </>}
          </>
        :
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff'
                }}
              >
                <img style={{ width: '100%', height: window.innerHeight - 70}} src={toAbsoluteUrl('/media/logos/inactive-report3.jpg')} alt=""/>

                <div style={{ padding: '10px', display: !isFirst ? 'block' : 'none' }}>
                  <div
                    onClick={() => dispatch(auth.actions.logout())}
                    style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                    <h4 style={{ color: '#fff' }}>
                      {LANGUAGE === 'VI'
                        ? 'Đăng xuất'
                        : 'Log out'
                      }
                    </h4>
                  </div>
                </div>

                {/*height menu*/}
                <div style={{height: '70px', display: isFirst ? 'block' : 'none'}}></div>
                {/*menu*/}
                <div style={{
                  display: isFirst ? 'block' : 'none',
                  position: 'fixed',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: WIDTH,
                  height: '70px',
                  margin: 'auto'
                }}>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/report-image'>
                      <div style={{padding: '0px 13px'}}>
                        <div style={{border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'}}>
                          <img
                            alt='Logo'
                            className='h-50px'
                            src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/list-study'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                      />
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/intro'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/3-gioi-thieu.png')}
                      />
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/info'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                      />
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </>
      }

    </>
  )
}

export {Map}
