/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useLocation} from "react-router-dom";
import {MenuTwo} from '../components/MenuTwo'
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import SVG from "react-inlinesvg";

const Lv1Report = () => {
  const audioElement1 = useRef()
  const audioElement2 = useRef()
  const audioElement3 = useRef()
  const audioElement4 = useRef()
  const audioElement5 = useRef()
  const audioElement6 = useRef()
  const audioElement7 = useRef()
  const audioElement8 = useRef()
  const audioElement9 = useRef()
  const audioElement10 = useRef()
  const audioElement11 = useRef()
  const audioElement12 = useRef()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const characterId = currentUser.user.character_id;
  const minorCharacterId = currentUser.user.minor_character_id;
  const roadCharacterId = currentUser.user.road_character_id;
  const spiritLevel = currentUser.user.spirit_level;
  const isFirst = currentUser.user.is_first;
  const [showButton, setShowButton] = useState(true);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const reportVideo = useRef()

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;
  const isPaid = currentUser.user.is_paid;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#333333'
        }}
      >
        <>
          {/*video*/}
          <div
            style={{
              padding: '20px'
            }}
          >
            <button
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#30a3fc',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '15px',
                borderWidth: '0px',
                display: showButton ? 'block' : 'none'
              }}
              onClick={() => {
                if (reportVideo.current) {
                  reportVideo.current.play()
                }
                setShowButton(false)
              }}
            >
                      <span
                        style={{
                          fontSize: '18px',
                          fontWeight: 'bolder',
                          color: '#fff',
                          fontFamily: 'Linotte-Heavy',
                          marginBottom: '20px'
                        }}
                      >
                        NGHE PHÂN TÍCH TÍNH CÁCH
                      </span>
            </button>
            <video
              style={{
                borderRadius: '20px',
                display: !showButton ? 'block' : 'none'
              }}
              ref={reportVideo} onEnded={() => setShowButton(true)} className='w-100' src={"https://backend.robotanan.com/character/tinh_cach_" + characterId + ".mp4"}
            />
          </div>
          <div
            style={{
              width: '100%',
              height: '60px',
              paddingTop: '30px',
              paddingLeft: '20px',
              backgroundColor: '#333',
              paddingRight: '20px',
              justifyContent: 'space-between',
              display: 'none'
            }}
          >
            <h1 style={{
              color: 'white',
              fontFamily: 'Linotte-Heavy',
              fontSize: '24px'
            }}>
              {/**/}
            </h1>
            <div className='d-none'>
              <h1
                onClick={() => dispatch({
                  type: 'SET_LANGUAGE',
                  payload: {
                    language: 'VI'
                  }
                })}
                style={{color: LANGUAGE === 'VI' ? 'white' : '#ccc', display: 'inline-block'}}>
                VIE
              </h1>
              <h1 style={{color: '#ccc', display: 'inline-block', marginLeft: '10px', marginRight: '10px'}}>
                |
              </h1>
              <h1
                onClick={() => dispatch({
                  type: 'SET_LANGUAGE',
                  payload: {
                    language: 'EN'
                  }
                })}
                style={{color: LANGUAGE === 'EN' ? 'white' : '#ccc', display: 'inline-block'}}>
                ENG
              </h1>
            </div>
          </div>

          <div
            onClick={() => {window.open('https://anbibietban.com/report-tutorial', '_blank')}}
            style={{ padding: '20px' }}
          >
            <div
              style={{ padding: '15px', borderRadius: '10px', backgroundColor: '#fe0000', cursor: 'pointer', display: 'flex' }}
            >
              <div
                style={{ width: '25%' }}
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/read.png')}
                  className='w-60px'
                />
              </div>
              <div
                style={{ width: '75%' }}
              >
                <h4 style={{ color: '#fff', fontSize: '20px', textTransform: 'uppercase', lineHeight: 1.6, marginBottom: '0px' }}>
                  Hướng dẫn Đọc hiểu
                  <br/>
                  và ứng dụng báo cáo
                </h4>
              </div>
            </div>
          </div>

          {/*ảnh 3 linh vật*/}
          {characterId === 1 && (
            <>
              {minorCharacterId === 9 && roadCharacterId === 7 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 9 && roadCharacterId === 4 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 2 && roadCharacterId === 7 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 2 && roadCharacterId === 4 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 9 && roadCharacterId === 7 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 9 && roadCharacterId === 4 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 2 && roadCharacterId === 7 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 2 && roadCharacterId === 4 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}

          {characterId === 2 && (
            <>
              {minorCharacterId === 1 && roadCharacterId === 4 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 1 && roadCharacterId === 8 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 3 && roadCharacterId === 4 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 3 && roadCharacterId === 8 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 1 && roadCharacterId === 4 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 1 && roadCharacterId === 8 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 3 && roadCharacterId === 4 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 3 && roadCharacterId === 8 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}

          {characterId === 3 && (
            <>
              {minorCharacterId === 2 && roadCharacterId === 6 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 2 && roadCharacterId === 9 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 4 && roadCharacterId === 6 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 4 && roadCharacterId === 9 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 2 && roadCharacterId === 6 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 2 && roadCharacterId === 9 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 4 && roadCharacterId === 6 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 4 && roadCharacterId === 9 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}

          {characterId === 4 && (
            <>
              {minorCharacterId === 3 && roadCharacterId === 1 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 3 && roadCharacterId === 2 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 5 && roadCharacterId === 1 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 5 && roadCharacterId === 2 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 3 && roadCharacterId === 1 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 3 && roadCharacterId === 2 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 5 && roadCharacterId === 1 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 5 && roadCharacterId === 2 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}

          {characterId === 5 && (
            <>
              {minorCharacterId === 4 && roadCharacterId === 8 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 4 && roadCharacterId === 7 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 6 && roadCharacterId === 8 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 6 && roadCharacterId === 7 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 4 && roadCharacterId === 8 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 4 && roadCharacterId === 7 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 6 && roadCharacterId === 8 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 6 && roadCharacterId === 7 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}

          {characterId === 6 && (
            <>
              {minorCharacterId === 5 && roadCharacterId === 9 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 5 && roadCharacterId === 3 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 7 && roadCharacterId === 9 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 7 && roadCharacterId === 3 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 5 && roadCharacterId === 9 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 5 && roadCharacterId === 3 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 7 && roadCharacterId === 9 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 7 && roadCharacterId === 3 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}

          {characterId === 7 && (
            <>
              {minorCharacterId === 6 && roadCharacterId === 5 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 6 && roadCharacterId === 1 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 8 && roadCharacterId === 5 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 8 && roadCharacterId === 1 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 6 && roadCharacterId === 5 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 6 && roadCharacterId === 1 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 8 && roadCharacterId === 5 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 8 && roadCharacterId === 1 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}

          {characterId === 8 && (
            <>
              {minorCharacterId === 7 && roadCharacterId === 2 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 7 && roadCharacterId === 5 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 9 && roadCharacterId === 2 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 9 && roadCharacterId === 5 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 7 && roadCharacterId === 2 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 7 && roadCharacterId === 5 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 9 && roadCharacterId === 2 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 9 && roadCharacterId === 5 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}

          {characterId === 9 && (
            <>
              {minorCharacterId === 8 && roadCharacterId === 3 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow : currentUser.user.character_adult.main_even_grow_en} alt=""/>}
              {minorCharacterId === 8 && roadCharacterId === 6 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress : currentUser.user.character_adult.main_even_stress_en} alt=""/>}
              {minorCharacterId === 1 && roadCharacterId === 3 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_grow : currentUser.user.character_adult.main_odd_grow_en} alt=""/>}
              {minorCharacterId === 1 && roadCharacterId === 6 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_odd_stress : currentUser.user.character_adult.main_odd_stress_en} alt=""/>}

              {minorCharacterId === 8 && roadCharacterId === 3 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 8 && roadCharacterId === 6 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_grow_skip : currentUser.user.character_adult.main_even_grow_skip} alt=""/>}
              {minorCharacterId === 1 && roadCharacterId === 3 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
              {minorCharacterId === 1 && roadCharacterId === 6 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_even_stress_skip : currentUser.user.character_adult.main_even_stress_skip} alt=""/>}
            </>
          )}
          {/*ảnh 3 linh vật*/}


          {/*sơ lược*/}
          <div
            style={{
              padding: '20px',
            }}
          >
            <div
              style={{
                backgroundColor: '#f2f2f2',
                borderRadius: '15px',
                padding: '20px',
              }}
            >
              <div
                style={{
                  backgroundColor: '#333333',
                  borderRadius: '20px',
                  margin: '20px',
                  padding: '10px',
                }}
              >
                <img
                  className='w-40px'
                  alt='user-icon'
                  src={toAbsoluteUrl('/media/logos/user-icon.png')}
                />
                <span
                  style={{
                    color: '#fff',
                    fontSize: '24px',
                    marginLeft: '10px',
                    fontFamily: 'UTM-ALTER-GOTHIC'
                  }}
                >
                  {currentUser.user.name}
                </span>
              </div>

              {/*chính phụ chặng*/}
              <div
                style={{
                  // backgroundColor: '#000'
                }}
              >
                {/*  <div*/}
                {/*    style={{*/}
                {/*      width: '25%',*/}
                {/*      // backgroundColor: 'red',*/}
                {/*      display: 'inline-block'*/}
                {/*    }}*/}
                {/*  >*/}
                {/*<span*/}
                {/*  style={{*/}
                {/*    color: '#333',*/}
                {/*    fontSize: '26px',*/}
                {/*    lineHeight: 1.2,*/}
                {/*    fontFamily: 'Linotte-Regular',*/}
                {/*  }}*/}
                {/*>*/}
                {/*  SƠ LƯỢC*/}
                {/*</span>*/}
                {/*  </div>*/}
                <div
                  style={{
                    width: '100%',
                    // backgroundColor: 'green',
                    display: 'inline-block'
                  }}
                >

                  {/*tính cách chính*/}
                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách chính:' : 'Primary personality:'}
                    </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' && <>
                        {currentUser.user.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                        {currentUser.user.character_id === 2 && ' THỎ ẤM ÁP'}
                        {currentUser.user.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                        {currentUser.user.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                        {currentUser.user.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                        {currentUser.user.character_id === 6 && ' SÓI TRUNG THÀNH'}
                        {currentUser.user.character_id === 7 && ' KHỈ LINH HOẠT'}
                        {currentUser.user.character_id === 8 && ' HỔ MẠNH MẼ'}
                        {currentUser.user.character_id === 9 && ' VOI ÔN HÒA'}
                      </>}
                      {LANGUAGE === 'EN' && <>
                        {currentUser.user.character_id === 1 && ' PRINCIPALED EAGLE'}
                        {currentUser.user.character_id === 2 && ' WARM RABBIT'}
                        {currentUser.user.character_id === 3 && ' MAJESTIC HORSE'}
                        {currentUser.user.character_id === 4 && ' UNIQUE UNICORN'}
                        {currentUser.user.character_id === 5 && ' WISE OWL'}
                        {currentUser.user.character_id === 6 && ' LOYAL WOLF'}
                        {currentUser.user.character_id === 7 && ' AGILE MONKEY'}
                        {currentUser.user.character_id === 8 && ' MIGHTY TIGER'}
                        {currentUser.user.character_id === 9 && ' HARMONIOUS ELEPHANT'}
                      </>}
                    </span>
                  </div>

                  {/*tính cách phụ*/}
                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách phụ:' : 'Secondary personality:'}
                    </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                                {LANGUAGE === 'VI' && <>
                                  {currentUser.user.minor_character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {currentUser.user.minor_character_id === 2 && ' THỎ ẤM ÁP'}
                                  {currentUser.user.minor_character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {currentUser.user.minor_character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {currentUser.user.minor_character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {currentUser.user.minor_character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {currentUser.user.minor_character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {currentUser.user.minor_character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {currentUser.user.minor_character_id === 9 && ' VOI ÔN HÒA'}
                                </>}
                      {LANGUAGE === 'EN' && <>
                        {currentUser.user.minor_character_id === 1 && ' PRINCIPALED EAGLE'}
                        {currentUser.user.minor_character_id === 2 && ' WARM RABBIT'}
                        {currentUser.user.minor_character_id === 3 && ' MAJESTIC HORSE'}
                        {currentUser.user.minor_character_id === 4 && ' UNIQUE UNICORN'}
                        {currentUser.user.minor_character_id === 5 && ' WISE OWL'}
                        {currentUser.user.minor_character_id === 6 && ' LOYAL WOLF'}
                        {currentUser.user.minor_character_id === 7 && ' AGILE MONKEY'}
                        {currentUser.user.minor_character_id === 8 && ' MIGHTY TIGER'}
                        {currentUser.user.minor_character_id === 9 && ' HARMONIOUS ELEPHANT'}
                      </>}

                    </span>
                  </div>

                  {/*tính cách chặng*/}
                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách chặng:' : 'Transitional personality'}
                    </span>

                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                                {spiritLevel && (
                                  <>
                                    {LANGUAGE === 'VI' && <>
                                      {currentUser.user.road_character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                      {currentUser.user.road_character_id === 2 && ' THỎ ẤM ÁP'}
                                      {currentUser.user.road_character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                      {currentUser.user.road_character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                      {currentUser.user.road_character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                      {currentUser.user.road_character_id === 6 && ' SÓI TRUNG THÀNH'}
                                      {currentUser.user.road_character_id === 7 && ' KHỈ LINH HOẠT'}
                                      {currentUser.user.road_character_id === 8 && ' HỔ MẠNH MẼ'}
                                      {currentUser.user.road_character_id === 9 && ' VOI ÔN HÒA'}
                                    </>}
                                    {LANGUAGE === 'EN' && <>
                                      {currentUser.user.road_character_id === 1 && ' PRINCIPALED EAGLE'}
                                      {currentUser.user.road_character_id === 2 && ' WARM RABBIT'}
                                      {currentUser.user.road_character_id === 3 && ' MAJESTIC HORSE'}
                                      {currentUser.user.road_character_id === 4 && ' UNIQUE UNICORN'}
                                      {currentUser.user.road_character_id === 5 && ' WISE OWL'}
                                      {currentUser.user.road_character_id === 6 && ' LOYAL WOLF'}
                                      {currentUser.user.road_character_id === 7 && ' AGILE MONKEY'}
                                      {currentUser.user.road_character_id === 8 && ' MIGHTY TIGER'}
                                      {currentUser.user.road_character_id === 9 && ' HARMONIOUS ELEPHANT'}
                                    </>}
                                  </>
                                )}
                      {!spiritLevel && ' KHÔNG CÓ DỮ LIỆU'}
                    </span>
                  </div>

                  {/*sức khỏe tâm lý*/}
                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Sức khỏe tinh thần:' : 'Mental health'}
                    </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                                {spiritLevel && <>{' ' + currentUser.user.spirit_level + '/9'}</>}
                      {!spiritLevel && ' KHÔNG CÓ DỮ LIỆU'}
                    </span>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Số điện thoại: ' : 'Phone number: '}
                    </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                                {currentUser.user.phone}
                    </span>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Mã quét mặt: ' : 'Scan code: '}
                    </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                                {currentUser.user.scan_code}
                    </span>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Mã cộng tác viên: ' : 'Sale code: '}
                    </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                                {currentUser.user.sale ? currentUser.user.sale.alias : ''}
                    </span>
                  </div>
                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {'Để đăng ký nhận mã quà tặng mới cho tập thể khác, vui lòng liên hệ SĐT: '}
                    </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {currentUser.user.agency?.phone ?? ''}
                    </span>
                  </div>



                </div>


              </div>
            </div>

          </div>
          {/*sơ lược*/}


          {/*tính cách chính*/}
          <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_character : currentUser.user.character_adult.main_character_en} alt=""/>
          {/*tính cách chính*/}


          {/*tính cách phụ*/}
          {characterId === 1 && (
            <>
              {minorCharacterId === 9 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 2 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {characterId === 2 && (
            <>
              {minorCharacterId === 1 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 3 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {characterId === 3 && (
            <>
              {minorCharacterId === 2 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 4 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {characterId === 4 && (
            <>
              {minorCharacterId === 3 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 5 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {characterId === 5 && (
            <>
              {minorCharacterId === 4 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 6 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {characterId === 6 && (
            <>
              {minorCharacterId === 5 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 7 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {characterId === 7 && (
            <>
              {minorCharacterId === 6 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 8 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {characterId === 8 && (
            <>
              {minorCharacterId === 7 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 9 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {characterId === 9 && (
            <>
              {minorCharacterId === 8 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_even : currentUser.user.character_adult.minor_character_even_en} alt=""/>}
              {minorCharacterId === 1 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.minor_character_odd : currentUser.user.character_adult.minor_character_odd_en} alt=""/>}
            </>
          )}
          {/*tính cách phụ*/}

          {/*tính cách chặng*/}
          {characterId === 1 && (
            <>
              {roadCharacterId === 7 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 4 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 7 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 4 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {characterId === 2 && (
            <>
              {roadCharacterId === 4 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 8 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 4 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 8 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {characterId === 3 && (
            <>
              {roadCharacterId === 6 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 9 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 6 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 9 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {characterId === 4 && (
            <>
              {roadCharacterId === 1 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 2 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 1 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 2 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {characterId === 5 && (
            <>
              {roadCharacterId === 8 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 7 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 8 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 7 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {characterId === 6 && (
            <>
              {roadCharacterId === 9 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 3 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 9 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 3 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {characterId === 7 && (
            <>
              {roadCharacterId === 5 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 1 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 5 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 1 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {characterId === 8 && (
            <>
              {roadCharacterId === 2 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 5 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 2 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 5 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {characterId === 9 && (
            <>
              {roadCharacterId === 3 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_grow : currentUser.user.character_adult.road_character_grow_en} alt=""/>}
              {roadCharacterId === 6 && spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_stress : currentUser.user.character_adult.road_character_stress_en} alt=""/>}

              {roadCharacterId === 3 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
              {roadCharacterId === 6 && !spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.road_character_skip : currentUser.user.character_adult.road_character_skip} alt=""/>}
            </>
          )}
          {/*tính cách chặng*/}


          {/*sức khỏe tinh thần*/}
          {spiritLevel === 1 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_one : currentUser.user.character_adult.spirit_one_en} alt=""/>}
          {spiritLevel === 2 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_two : currentUser.user.character_adult.spirit_two_en} alt=""/>}
          {spiritLevel === 3 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_three : currentUser.user.character_adult.spirit_three_en} alt=""/>}
          {spiritLevel === 4 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_four : currentUser.user.character_adult.spirit_four_en} alt=""/>}
          {spiritLevel === 5 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_five : currentUser.user.character_adult.spirit_five_en} alt=""/>}
          {spiritLevel === 6 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_six : currentUser.user.character_adult.spirit_six_en} alt=""/>}
          {spiritLevel === 7 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_seven : currentUser.user.character_adult.spirit_seven_en} alt=""/>}
          {spiritLevel === 8 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_eight : currentUser.user.character_adult.spirit_eight_en} alt=""/>}
          {spiritLevel === 9 && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_nine : currentUser.user.character_adult.spirit_nine_en} alt=""/>}

          {!spiritLevel && <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.spirit_skip : currentUser.user.character_adult.spirit_skip} alt=""/>}
          {/*sức khỏe tinh thần*/}

          {/*thông tin chuyển khoản*/}
          {!isPaid &&
          <div
            style={{
              padding: '20px'
            }}
          >
            <div
              style={{
                width: '100%',
                margin: 'auto',
                backgroundColor: '#fff',
                borderRadius: '20px',
              }}
            >
              <div
                style={{
                  backgroundColor: '#f2f2f2',
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'inline-block'
                  }}
                >

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                    <p
                      style={{
                        color: '#000',
                        fontSize: '20px',
                        fontFamily: 'Linotte-Regular',
                        textAlign: 'center'
                      }}
                    >
                      12 thông tin quan trọng về tính cách này sẽ được lưu trữ trong 01 tuần. Nếu bạn muốn giữ tài khoản lâu hơn để thường xuyên xem lại báo cáo, nhận các bản cập nhật và tham gia các khóa học xin vui lòng chuyển khoản 50.000đ (2 USD) để lưu trữ thông tin trong 01 năm. Hệ thống tự động cập nhật trạng thái tài khoản sau tối đa 24 giờ kể từ khi chuyển khoản.
                    </p>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Ngân hàng: '}
                              </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'Linotte-Regular'
                      }}
                    >
                                Quân đội MBBank
                              </span>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tài khoản: '}
                              </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'Linotte-Regular'
                      }}
                    >
                                1988288288
                              </span>
                    <br/>
                    <button className='btn btn-success btn-sm ms-4'
                            style={{ marginTop: '0px' }}
                            onClick={() => navigator.clipboard.writeText('1988288288')}
                    >
                      Copy
                    </button>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                <span
                  style={{
                    color: '#333',
                    fontSize: '20px',
                    fontFamily: 'Linotte-Regular'
                  }}
                >
                  {'Tên tài khoản: '}
                </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'Linotte-Regular'
                      }}
                    >
                  Trần Ngọc Thiện
                </span>
                  </div>


                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Số tiền: '}
                              </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'Linotte-Regular'
                      }}
                    >
                                50.000 đ
                              </span>
                  </div>

                  <div
                    style={{
                      borderBottom: '1px dashed #b0b0b0',
                      margin: '5px',
                      padding: '10px 10px 10px 0px'
                    }}
                  >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'Linotte-Regular'
                                }}
                              >
                                {'Nội dung: '}
                              </span>
                    <span
                      style={{
                        color: '#fd1a1c',
                        fontSize: '20px',
                        fontFamily: 'Linotte-Regular'
                      }}
                    >
                                  {currentUser.user.phone}
                                </span>
                    <br/>
                    <button className='btn btn-success btn-sm ms-4'
                            style={{ marginTop: '0px' }}
                            onClick={() => navigator.clipboard.writeText(currentUser.user.phone)}
                    >
                      Copy
                    </button>
                  </div>

                  <div
                    style={{
                      margin: '5px',
                      padding: '10px 10px 10px 0px',
                      textAlign: 'center'
                    }}
                  >
                <span
                  style={{
                    color: '#333',
                    fontSize: '22px',
                    fontFamily: 'Linotte-Regular'
                  }}
                >
                  {'Mã QR: '}
                </span>
                    <br/>
                    <div className='text-center'>
                      <img style={{ width: '90%', borderRadius: '5px', marginTop: '10px' }} src={toAbsoluteUrl('/media/logos/qr-tai-tro.jpg')} alt=""/>
                    </div>
                  </div>

                </div>
              </div>



              {/*<MenuTwo active='study'/>*/}
            </div>
          </div>
          }

          {/*tổng quan*/}
          <img style={{ width: WIDTH, height: 'auto' }} src={LANGUAGE === 'VI' ? currentUser.user.character_adult.main_overview : currentUser.user.character_adult.main_overview_en} alt=""/>
          {/*tổng quan*/}

          {/*audio tiếng việt start*/}
          {LANGUAGE === 'VI' && (
            <>
              <div
                style={{
                  padding: '20px',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#f2f2f2',
                    borderRadius: '15px',
                    padding: '20px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#333333',
                      borderRadius: '20px',
                      margin: '20px',
                      padding: '10px',
                    }}
                  >
                    <img
                      className='w-40px'
                      alt='user-icon'
                      src={toAbsoluteUrl('/media/logos/user-icon.png')}
                    />
                    <span
                      style={{
                        color: '#fff',
                        fontSize: '24px',
                        marginLeft: '10px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                  {currentUser.user.name}
                </span>
                  </div>

                  {/*chính phụ chặng*/}
                  <div
                    style={{
                      // backgroundColor: '#000'
                    }}
                  >
                    {/*  <div*/}
                    {/*    style={{*/}
                    {/*      width: '25%',*/}
                    {/*      // backgroundColor: 'red',*/}
                    {/*      display: 'inline-block'*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*<span*/}
                    {/*  style={{*/}
                    {/*    color: '#333',*/}
                    {/*    fontSize: '26px',*/}
                    {/*    lineHeight: 1.2,*/}
                    {/*    fontFamily: 'Linotte-Regular',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  SƠ LƯỢC*/}
                    {/*</span>*/}
                    {/*  </div>*/}
                    <div
                      style={{
                        width: '100%',
                        // backgroundColor: 'green',
                        display: 'inline-block'
                      }}
                    >

                      {/*tính cách chính*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách chính:' : 'Primary personality:'}
                    </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '22px',
                            fontFamily: 'UTM-ALTER-GOTHIC'
                          }}
                        >
                      {LANGUAGE === 'VI' && <>
                        {currentUser.user.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                        {currentUser.user.character_id === 2 && ' THỎ ẤM ÁP'}
                        {currentUser.user.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                        {currentUser.user.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                        {currentUser.user.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                        {currentUser.user.character_id === 6 && ' SÓI TRUNG THÀNH'}
                        {currentUser.user.character_id === 7 && ' KHỈ LINH HOẠT'}
                        {currentUser.user.character_id === 8 && ' HỔ MẠNH MẼ'}
                        {currentUser.user.character_id === 9 && ' VOI ÔN HÒA'}
                      </>}
                          {LANGUAGE === 'EN' && <>
                            {currentUser.user.character_id === 1 && ' PRINCIPALED EAGLE'}
                            {currentUser.user.character_id === 2 && ' WARM RABBIT'}
                            {currentUser.user.character_id === 3 && ' MAJESTIC HORSE'}
                            {currentUser.user.character_id === 4 && ' UNIQUE UNICORN'}
                            {currentUser.user.character_id === 5 && ' WISE OWL'}
                            {currentUser.user.character_id === 6 && ' LOYAL WOLF'}
                            {currentUser.user.character_id === 7 && ' AGILE MONKEY'}
                            {currentUser.user.character_id === 8 && ' MIGHTY TIGER'}
                            {currentUser.user.character_id === 9 && ' HARMONIOUS ELEPHANT'}
                          </>}
                    </span>
                        <audio ref={audioElement1} controls onEnded={() => {
                          if (audioElement2) {
                            audioElement2.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}01.m4a`} type="audio/wav" />
                        </audio>
                      </div>

                      {/*tính cách phụ*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách phụ:' : 'Secondary personality:'}
                    </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '22px',
                            fontFamily: 'UTM-ALTER-GOTHIC'
                          }}
                        >
                                {LANGUAGE === 'VI' && <>
                                  {currentUser.user.minor_character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {currentUser.user.minor_character_id === 2 && ' THỎ ẤM ÁP'}
                                  {currentUser.user.minor_character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {currentUser.user.minor_character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {currentUser.user.minor_character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {currentUser.user.minor_character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {currentUser.user.minor_character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {currentUser.user.minor_character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {currentUser.user.minor_character_id === 9 && ' VOI ÔN HÒA'}
                                </>}
                          {LANGUAGE === 'EN' && <>
                            {currentUser.user.minor_character_id === 1 && ' PRINCIPALED EAGLE'}
                            {currentUser.user.minor_character_id === 2 && ' WARM RABBIT'}
                            {currentUser.user.minor_character_id === 3 && ' MAJESTIC HORSE'}
                            {currentUser.user.minor_character_id === 4 && ' UNIQUE UNICORN'}
                            {currentUser.user.minor_character_id === 5 && ' WISE OWL'}
                            {currentUser.user.minor_character_id === 6 && ' LOYAL WOLF'}
                            {currentUser.user.minor_character_id === 7 && ' AGILE MONKEY'}
                            {currentUser.user.minor_character_id === 8 && ' MIGHTY TIGER'}
                            {currentUser.user.minor_character_id === 9 && ' HARMONIOUS ELEPHANT'}
                          </>}

                    </span>
                        {(currentUser.user.character_id === 1 && currentUser.user.minor_character_id === 9) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 1 && currentUser.user.minor_character_id === 2) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 2 && currentUser.user.minor_character_id === 1) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 2 && currentUser.user.minor_character_id === 3) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 3 && currentUser.user.minor_character_id === 2) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 3 && currentUser.user.minor_character_id === 4) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 4 && currentUser.user.minor_character_id === 3) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 4 && currentUser.user.minor_character_id === 5) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 5 && currentUser.user.minor_character_id === 4) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 5 && currentUser.user.minor_character_id === 6) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 6 && currentUser.user.minor_character_id === 5) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 6 && currentUser.user.minor_character_id === 7) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 7 && currentUser.user.minor_character_id === 6) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 7 && currentUser.user.minor_character_id === 8) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 8 && currentUser.user.minor_character_id === 7) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 8 && currentUser.user.minor_character_id === 9) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 9 && currentUser.user.minor_character_id === 8) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 9 && currentUser.user.minor_character_id === 1) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                      </div>

                      {/*tính cách chặng*/}
                      <div
                        style={{
                          display: spiritLevel ? 'block' : 'none',
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách chặng:' : 'Transitional personality'}
                    </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '22px',
                            fontFamily: 'UTM-ALTER-GOTHIC'
                          }}
                        >
                                {LANGUAGE === 'VI' && <>
                                  {currentUser.user.road_character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {currentUser.user.road_character_id === 2 && ' THỎ ẤM ÁP'}
                                  {currentUser.user.road_character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {currentUser.user.road_character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {currentUser.user.road_character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {currentUser.user.road_character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {currentUser.user.road_character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {currentUser.user.road_character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {currentUser.user.road_character_id === 9 && ' VOI ÔN HÒA'}
                                </>}
                          {LANGUAGE === 'EN' && <>
                            {currentUser.user.road_character_id === 1 && ' PRINCIPALED EAGLE'}
                            {currentUser.user.road_character_id === 2 && ' WARM RABBIT'}
                            {currentUser.user.road_character_id === 3 && ' MAJESTIC HORSE'}
                            {currentUser.user.road_character_id === 4 && ' UNIQUE UNICORN'}
                            {currentUser.user.road_character_id === 5 && ' WISE OWL'}
                            {currentUser.user.road_character_id === 6 && ' LOYAL WOLF'}
                            {currentUser.user.road_character_id === 7 && ' AGILE MONKEY'}
                            {currentUser.user.road_character_id === 8 && ' MIGHTY TIGER'}
                            {currentUser.user.road_character_id === 9 && ' HARMONIOUS ELEPHANT'}
                          </>}
                    </span>
                        {(currentUser.user.character_id === 1 && currentUser.user.road_character_id === 7) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 1 && currentUser.user.road_character_id === 4) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 2 && currentUser.user.road_character_id === 4) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 2 && currentUser.user.road_character_id === 8) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 3 && currentUser.user.road_character_id === 6) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 3 && currentUser.user.road_character_id === 9) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 4 && currentUser.user.road_character_id === 1) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 4 && currentUser.user.road_character_id === 2) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 5 && currentUser.user.road_character_id === 8) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 5 && currentUser.user.road_character_id === 7) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 6 && currentUser.user.road_character_id === 9) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 6 && currentUser.user.road_character_id === 3) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 7 && currentUser.user.road_character_id === 5) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 7 && currentUser.user.road_character_id === 1) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 8 && currentUser.user.road_character_id === 2) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 8 && currentUser.user.road_character_id === 5) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 9 && currentUser.user.road_character_id === 3) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 9 && currentUser.user.road_character_id === 6) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                      </div>

                      {/*sức khỏe tâm lý*/}
                      <div
                        style={{
                          display: spiritLevel ? 'block' : 'none',
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Sức khỏe tinh thần:' : 'Mental health'}
                    </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '22px',
                            fontFamily: 'UTM-ALTER-GOTHIC'
                          }}
                        >
                      {' ' + currentUser.user.spirit_level + '/9'}
                    </span>
                        {(currentUser.user.spirit_level === 1) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}18.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 2) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}19.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 3) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}06.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 4) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}07.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 5) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}08.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 6) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}09.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 7) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}20.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 8) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}21.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 9) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}22.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                      </div>

                      {/*dac diem cot loi start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Đặc điểm cốt lõi:' : 'Core characteristics'}
                              </span>

                        <audio ref={audioElement5} controls onEnded={() => {
                          if (audioElement6) {
                            audioElement6.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}10.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*dac diem cot loi end*/}

                      {/*dac diem tinh cach start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Đặc điểm tính cách:' : 'Personality traits'}
                              </span>

                        <audio ref={audioElement6} controls onEnded={() => {
                          if (audioElement7) {
                            audioElement7.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}11.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*dac diem tinh cach end*/}

                      {/*diem manh start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Điểm mạnh:' : 'Strength'}
                              </span>

                        <audio ref={audioElement7} controls onEnded={() => {
                          if (audioElement8) {
                            audioElement8.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}12.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*diem manh end*/}

                      {/*diem yeu start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Điểm yếu:' : 'Weakness'}
                              </span>

                        <audio ref={audioElement8} controls onEnded={() => {
                          if (audioElement9) {
                            audioElement9.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}13.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*diem yeu end*/}

                      {/*nang luc start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Năng lực:' : 'Capacity'}
                              </span>

                        <audio ref={audioElement9} controls onEnded={() => {
                          if (audioElement10) {
                            audioElement10.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}14.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*nang luc end*/}

                      {/*bai hoc phat trien start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Bài học phát triển:' : 'Development lessons'}
                              </span>

                        <audio ref={audioElement10} controls onEnded={() => {
                          if (audioElement11) {
                            audioElement11.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}15.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*bai hoc phat trien end*/}

                      {/*moi truong lam viec start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Môi trường làm việc:' : 'Work environment'}
                              </span>

                        <audio ref={audioElement11} controls onEnded={() => {
                          if (audioElement12) {
                            audioElement12.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}16.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*moi truong lam viec end*/}

                      {/*xu huong nghe nghiep start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Xu hướng nghề nghiệp:' : 'Career trends'}
                              </span>

                        <audio ref={audioElement12} controls>
                          <source src={`https://backend.robotanan.com/audio/a${currentUser.user.character_id}17.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*xu huong nghe nghiep end*/}

                    </div>


                  </div>
                </div>
              </div>
            </>
          )}
          {/*audio tiếng việt end*/}

          {/*audio tiếng anh start*/}
          {LANGUAGE === 'EN' && (
            <>
              <div
                style={{
                  padding: '20px',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#f2f2f2',
                    borderRadius: '15px',
                    padding: '20px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#333333',
                      borderRadius: '20px',
                      margin: '20px',
                      padding: '10px',
                    }}
                  >
                    <img
                      className='w-40px'
                      alt='user-icon'
                      src={toAbsoluteUrl('/media/logos/user-icon.png')}
                    />
                    <span
                      style={{
                        color: '#fff',
                        fontSize: '24px',
                        marginLeft: '10px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                  {currentUser.user.name}
                </span>
                  </div>

                  {/*chính phụ chặng*/}
                  <div
                    style={{
                      // backgroundColor: '#000'
                    }}
                  >
                    {/*  <div*/}
                    {/*    style={{*/}
                    {/*      width: '25%',*/}
                    {/*      // backgroundColor: 'red',*/}
                    {/*      display: 'inline-block'*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*<span*/}
                    {/*  style={{*/}
                    {/*    color: '#333',*/}
                    {/*    fontSize: '26px',*/}
                    {/*    lineHeight: 1.2,*/}
                    {/*    fontFamily: 'Linotte-Regular',*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  SƠ LƯỢC*/}
                    {/*</span>*/}
                    {/*  </div>*/}
                    <div
                      style={{
                        width: '100%',
                        // backgroundColor: 'green',
                        display: 'inline-block'
                      }}
                    >

                      {/*tính cách chính*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách chính:' : 'Primary personality:'}
                    </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '22px',
                            fontFamily: 'UTM-ALTER-GOTHIC'
                          }}
                        >
                      {LANGUAGE === 'VI' && <>
                        {currentUser.user.character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                        {currentUser.user.character_id === 2 && ' THỎ ẤM ÁP'}
                        {currentUser.user.character_id === 3 && ' TUẤN MÃ UY PHONG'}
                        {currentUser.user.character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                        {currentUser.user.character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                        {currentUser.user.character_id === 6 && ' SÓI TRUNG THÀNH'}
                        {currentUser.user.character_id === 7 && ' KHỈ LINH HOẠT'}
                        {currentUser.user.character_id === 8 && ' HỔ MẠNH MẼ'}
                        {currentUser.user.character_id === 9 && ' VOI ÔN HÒA'}
                      </>}
                          {LANGUAGE === 'EN' && <>
                            {currentUser.user.character_id === 1 && ' PRINCIPALED EAGLE'}
                            {currentUser.user.character_id === 2 && ' WARM RABBIT'}
                            {currentUser.user.character_id === 3 && ' MAJESTIC HORSE'}
                            {currentUser.user.character_id === 4 && ' UNIQUE UNICORN'}
                            {currentUser.user.character_id === 5 && ' WISE OWL'}
                            {currentUser.user.character_id === 6 && ' LOYAL WOLF'}
                            {currentUser.user.character_id === 7 && ' AGILE MONKEY'}
                            {currentUser.user.character_id === 8 && ' MIGHTY TIGER'}
                            {currentUser.user.character_id === 9 && ' HARMONIOUS ELEPHANT'}
                          </>}
                    </span>
                        <audio ref={audioElement1} controls onEnded={() => {
                          if (audioElement2) {
                            audioElement2.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}01.m4a`} type="audio/wav" />
                        </audio>
                      </div>

                      {/*tính cách phụ*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách phụ:' : 'Secondary personality:'}
                    </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '22px',
                            fontFamily: 'UTM-ALTER-GOTHIC'
                          }}
                        >
                                {LANGUAGE === 'VI' && <>
                                  {currentUser.user.minor_character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {currentUser.user.minor_character_id === 2 && ' THỎ ẤM ÁP'}
                                  {currentUser.user.minor_character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {currentUser.user.minor_character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {currentUser.user.minor_character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {currentUser.user.minor_character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {currentUser.user.minor_character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {currentUser.user.minor_character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {currentUser.user.minor_character_id === 9 && ' VOI ÔN HÒA'}
                                </>}
                          {LANGUAGE === 'EN' && <>
                            {currentUser.user.minor_character_id === 1 && ' PRINCIPALED EAGLE'}
                            {currentUser.user.minor_character_id === 2 && ' WARM RABBIT'}
                            {currentUser.user.minor_character_id === 3 && ' MAJESTIC HORSE'}
                            {currentUser.user.minor_character_id === 4 && ' UNIQUE UNICORN'}
                            {currentUser.user.minor_character_id === 5 && ' WISE OWL'}
                            {currentUser.user.minor_character_id === 6 && ' LOYAL WOLF'}
                            {currentUser.user.minor_character_id === 7 && ' AGILE MONKEY'}
                            {currentUser.user.minor_character_id === 8 && ' MIGHTY TIGER'}
                            {currentUser.user.minor_character_id === 9 && ' HARMONIOUS ELEPHANT'}
                          </>}

                    </span>
                        {(currentUser.user.character_id === 1 && currentUser.user.minor_character_id === 9) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 1 && currentUser.user.minor_character_id === 2) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 2 && currentUser.user.minor_character_id === 1) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 2 && currentUser.user.minor_character_id === 3) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 3 && currentUser.user.minor_character_id === 2) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 3 && currentUser.user.minor_character_id === 4) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 4 && currentUser.user.minor_character_id === 3) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 4 && currentUser.user.minor_character_id === 5) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 5 && currentUser.user.minor_character_id === 4) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 5 && currentUser.user.minor_character_id === 6) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 6 && currentUser.user.minor_character_id === 5) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 6 && currentUser.user.minor_character_id === 7) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 7 && currentUser.user.minor_character_id === 6) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 7 && currentUser.user.minor_character_id === 8) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 8 && currentUser.user.minor_character_id === 7) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 8 && currentUser.user.minor_character_id === 9) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 9 && currentUser.user.minor_character_id === 8) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}02.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 9 && currentUser.user.minor_character_id === 1) && <>
                          <audio ref={audioElement2} controls onEnded={() => {
                            if (audioElement3) {
                              audioElement3.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}03.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                      </div>

                      {/*tính cách chặng*/}
                      <div
                        style={{
                          display: spiritLevel ? 'block' : 'none',
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Tính cách chặng:' : 'Transitional personality'}
                    </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '22px',
                            fontFamily: 'UTM-ALTER-GOTHIC'
                          }}
                        >
                                {LANGUAGE === 'VI' && <>
                                  {currentUser.user.road_character_id === 1 && ' ĐẠI BÀNG NGUYÊN TẮC'}
                                  {currentUser.user.road_character_id === 2 && ' THỎ ẤM ÁP'}
                                  {currentUser.user.road_character_id === 3 && ' TUẤN MÃ UY PHONG'}
                                  {currentUser.user.road_character_id === 4 && ' KỲ LÂN ĐỘC ĐÁO'}
                                  {currentUser.user.road_character_id === 5 && ' CÚ MÈO LÝ TRÍ'}
                                  {currentUser.user.road_character_id === 6 && ' SÓI TRUNG THÀNH'}
                                  {currentUser.user.road_character_id === 7 && ' KHỈ LINH HOẠT'}
                                  {currentUser.user.road_character_id === 8 && ' HỔ MẠNH MẼ'}
                                  {currentUser.user.road_character_id === 9 && ' VOI ÔN HÒA'}
                                </>}
                          {LANGUAGE === 'EN' && <>
                            {currentUser.user.road_character_id === 1 && ' PRINCIPALED EAGLE'}
                            {currentUser.user.road_character_id === 2 && ' WARM RABBIT'}
                            {currentUser.user.road_character_id === 3 && ' MAJESTIC HORSE'}
                            {currentUser.user.road_character_id === 4 && ' UNIQUE UNICORN'}
                            {currentUser.user.road_character_id === 5 && ' WISE OWL'}
                            {currentUser.user.road_character_id === 6 && ' LOYAL WOLF'}
                            {currentUser.user.road_character_id === 7 && ' AGILE MONKEY'}
                            {currentUser.user.road_character_id === 8 && ' MIGHTY TIGER'}
                            {currentUser.user.road_character_id === 9 && ' HARMONIOUS ELEPHANT'}
                          </>}
                    </span>
                        {(currentUser.user.character_id === 1 && currentUser.user.road_character_id === 7) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 1 && currentUser.user.road_character_id === 4) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 2 && currentUser.user.road_character_id === 4) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 2 && currentUser.user.road_character_id === 8) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 3 && currentUser.user.road_character_id === 6) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 3 && currentUser.user.road_character_id === 9) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 4 && currentUser.user.road_character_id === 1) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 4 && currentUser.user.road_character_id === 2) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 5 && currentUser.user.road_character_id === 8) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 5 && currentUser.user.road_character_id === 7) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 6 && currentUser.user.road_character_id === 9) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 6 && currentUser.user.road_character_id === 3) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 7 && currentUser.user.road_character_id === 5) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 7 && currentUser.user.road_character_id === 1) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 8 && currentUser.user.road_character_id === 2) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 8 && currentUser.user.road_character_id === 5) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.character_id === 9 && currentUser.user.road_character_id === 3) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}04.m4a`} type="audio/wav" />
                          </audio>
                        </>}
                        {(currentUser.user.character_id === 9 && currentUser.user.road_character_id === 6) && <>
                          <audio ref={audioElement3} controls onEnded={() => {
                            if (audioElement4) {
                              audioElement4.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}05.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                      </div>

                      {/*sức khỏe tâm lý*/}
                      <div
                        style={{
                          display: spiritLevel ? 'block' : 'none',
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                    <span
                      style={{
                        color: '#333',
                        fontSize: '22px',
                        fontFamily: 'UTM-ALTER-GOTHIC'
                      }}
                    >
                      {LANGUAGE === 'VI' ? 'Sức khỏe tinh thần:' : 'Mental health'}
                    </span>
                        <span
                          style={{
                            color: '#fd1a1c',
                            fontSize: '22px',
                            fontFamily: 'UTM-ALTER-GOTHIC'
                          }}
                        >
                      {' ' + currentUser.user.spirit_level + '/9'}
                    </span>
                        {(currentUser.user.spirit_level === 1) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}18.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 2) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}19.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 3) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}06.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 4) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}07.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 5) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}08.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 6) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}09.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 7) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}20.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 8) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}21.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                        {(currentUser.user.spirit_level === 9) && <>
                          <audio ref={audioElement4} controls onEnded={() => {
                            if (audioElement5) {
                              audioElement5.current.play()
                            }
                          }}>
                            <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}22.m4a`} type="audio/wav" />
                          </audio>
                        </>}

                      </div>

                      {/*dac diem cot loi start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Đặc điểm cốt lõi:' : 'Core characteristics'}
                              </span>

                        <audio ref={audioElement5} controls onEnded={() => {
                          if (audioElement6) {
                            audioElement6.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}10.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*dac diem cot loi end*/}

                      {/*dac diem tinh cach start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Đặc điểm tính cách:' : 'Personality traits'}
                              </span>

                        <audio ref={audioElement6} controls onEnded={() => {
                          if (audioElement7) {
                            audioElement7.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}11.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*dac diem tinh cach end*/}

                      {/*diem manh start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Điểm mạnh:' : 'Strength'}
                              </span>

                        <audio ref={audioElement7} controls onEnded={() => {
                          if (audioElement8) {
                            audioElement8.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}12.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*diem manh end*/}

                      {/*diem yeu start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Điểm yếu:' : 'Weakness'}
                              </span>

                        <audio ref={audioElement8} controls onEnded={() => {
                          if (audioElement9) {
                            audioElement9.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}13.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*diem yeu end*/}

                      {/*nang luc start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Năng lực:' : 'Capacity'}
                              </span>

                        <audio ref={audioElement9} controls onEnded={() => {
                          if (audioElement10) {
                            audioElement10.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}14.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*nang luc end*/}

                      {/*bai hoc phat trien start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Bài học phát triển:' : 'Development lessons'}
                              </span>

                        <audio ref={audioElement10} controls onEnded={() => {
                          if (audioElement11) {
                            audioElement11.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}15.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*bai hoc phat trien end*/}

                      {/*moi truong lam viec start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Môi trường làm việc:' : 'Work environment'}
                              </span>

                        <audio ref={audioElement11} controls onEnded={() => {
                          if (audioElement12) {
                            audioElement12.current.play()
                          }
                        }}>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}16.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*moi truong lam viec end*/}

                      {/*xu huong nghe nghiep start*/}
                      <div
                        style={{
                          borderBottom: '1px dashed #b0b0b0',
                          margin: '5px',
                          padding: '10px 10px 10px 0px'
                        }}
                      >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-ALTER-GOTHIC'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Xu hướng nghề nghiệp:' : 'Career trends'}
                              </span>

                        <audio ref={audioElement12} controls>
                          <source src={`https://backend.robotanan.com/audio/e${currentUser.user.character_id}17.m4a`} type="audio/wav" />
                        </audio>

                      </div>
                      {/*xu huong nghe nghiep end*/}

                    </div>


                  </div>
                </div>
              </div>
            </>
          )}
          {/*audio tiếng anh end*/}

          {!currentUser.user.stock_quantity &&
          <div
            style={{
              padding: '20px 20px 40px 20px',
            }}
          >
            <div
              style={{
                backgroundColor: '#f2f2f2',
                borderRadius: '15px',
                padding: '20px',
              }}
            >
              <p
                style={{
                  color: '#333',
                  fontSize: '22px',
                  fontFamily: 'UTM-ALTER-GOTHIC',
                  textAlign: 'justify'
                }}
              >
                Mời bạn giúp đỡ chúng tôi hoàn thành đánh giá về chất lượng sản phẩm để chúng tôi ngày càng hoàn thiện hơn. Chân thành cảm ơn!
              </p>

              <div
                style={{
                  backgroundColor: '#333333',
                  borderRadius: '20px',
                  margin: '20px',
                  padding: '10px',
                  textAlign: 'center'
                }}
              >
                <SVG
                  onClick={() => setRating(1)}
                  style={{
                    width: '50px',
                    height: '40px',
                    color: 'yellow',
                    stroke: 'yellow',
                    cursor: 'pointer'
                  }}
                  src={toAbsoluteUrl(rating >= 1 ? '/media/logos/star-fill.svg' : '/media/logos/star.svg')}
                />
                <SVG
                  onClick={() => setRating(2)}
                  style={{
                    width: '50px',
                    height: '40px',
                    color: 'yellow',
                    stroke: 'yellow',
                    cursor: 'pointer'
                  }}
                  src={toAbsoluteUrl(rating >= 2 ? '/media/logos/star-fill.svg' : '/media/logos/star.svg')}
                />
                <SVG
                  onClick={() => setRating(3)}
                  style={{
                    width: '50px',
                    height: '40px',
                    color: 'yellow',
                    stroke: 'yellow',
                    cursor: 'pointer'
                  }}
                  src={toAbsoluteUrl(rating >= 3 ? '/media/logos/star-fill.svg' : '/media/logos/star.svg')}
                />
                <SVG
                  onClick={() => setRating(4)}
                  style={{
                    width: '50px',
                    height: '40px',
                    color: 'yellow',
                    stroke: 'yellow',
                    cursor: 'pointer'
                  }}
                  src={toAbsoluteUrl(rating >= 4 ? '/media/logos/star-fill.svg' : '/media/logos/star.svg')}
                />
                <SVG
                  onClick={() => setRating(5)}
                  style={{
                    width: '50px',
                    height: '40px',
                    color: 'yellow',
                    stroke: 'yellow',
                    cursor: 'pointer'
                  }}
                  src={toAbsoluteUrl(rating >= 5 ? '/media/logos/star-fill.svg' : '/media/logos/star.svg')}
                />
              </div>

              <div>
                <p
                  style={{
                    color: '#333',
                    fontSize: '18px',
                    fontFamily: 'UTM-ALTER-GOTHIC'
                  }}
                >
                  Nhận xét:
                </p>
                <input
                  style={{
                    color: '#333',
                    fontSize: '18px',
                    fontFamily: 'UTM-ALTER-GOTHIC'
                  }}
                  className="form-control"
                  type="text"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />

                <button
                  type="button"
                  style={{
                    width: '100%',
                    padding: '15px',
                    backgroundColor: '#30a3fc',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    borderWidth: '0px',
                    fontFamily: 'Linotte-Regular',
                    marginTop: '25px'
                  }}
                  onClick={() => {
                    axios.post(`${BACKEND_URL}/customer/send-rating`, {
                      access_token: currentUser.user.access_token,
                      rating,
                      comment
                    }).then(res => res.data)
                      .then(resData => {
                        window.location.replace('/')
                      })
                  }}
                >
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      color: '#fff',
                      letterSpacing: 1.1
                    }}
                  >
                    GỬI NHẬN XÉT
                  </span>
                </button>

              </div>
            </div>
          </div>
          }

        </>

        <Link to={{
          pathname: '/customer/info-change-password',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi mật khẩu'
                  : 'Change Password'
                }
              </h4>
            </div>
          </div>
        </Link>

        <Link to={{
          pathname: '/customer/info-change-name',
          state: { test: "hello" }
        }}>
          <div style={{ padding: '10px' }}>
            <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
              <h4 style={{ color: '#fff' }}>
                {LANGUAGE === 'VI'
                  ? 'Đổi tên'
                  : 'Change Name'
                }
              </h4>
            </div>
          </div>
        </Link>

        <div style={{ padding: '10px' }}>
          <div
            onClick={() => dispatch(auth.actions.logout())}
            style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
            <h4 style={{ color: '#fff' }}>
              {LANGUAGE === 'VI'
                ? 'Đăng xuất'
                : 'Log out'
              }
            </h4>
          </div>
        </div>

        {/*menu*/}
        {/*<MenuTwo active='report'/>*/}
      </div>

    </div>
  )
}

export {Lv1Report}
