/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";

const MenuTwo = ({active}) => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)

  const style = {
    menuContainer: {
      position: 'fixed',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: WIDTH,
      height: '70px',
      margin: 'auto'
    },
    menuElement: {
      width: '25%',
      height: '100%',
      backgroundColor: '#fff',
      display: 'inline-block',
      textAlign: 'center',
      paddingTop: '10px',
      paddingBottom: '10px',
    }
  }

  return (
    <>
      <div style={{height: '70px'}}>&nbsp;</div>
      <div style={style.menuContainer}>

        <div style={style.menuElement}>
          <Link to='/customer/report-image'>
            <div style={{padding: '0px 10px'}}>
              <div style={{border: active === 'report' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'report' ? '#ade0fd' : '#fff'}}>
                <img
                  style={{ marginTop: '0px', marginLeft: '0px' }}
                  alt='Logo'
                  className='h-55px'
                  src={toAbsoluteUrl(active === 'report' ? '/media/logos/menu11.png' : '/media/logos/menu12.png')}
                />
              </div>
            </div>
          </Link>
        </div>

        <div style={style.menuElement}>
          <Link to='/customer/list-study'>
            <div style={{padding: '0px 10px'}}>
              <div style={{border: active === 'study' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'study' ? '#ade0fd' : '#fff'}}>
                <img
                  style={{ marginTop: '0px', marginLeft: '0px' }}
                  alt='Logo'
                  className='h-55px'
                  src={toAbsoluteUrl(active === 'study' ? '/media/logos/menu21.png' : '/media/logos/menu22.png')}
                />
              </div>
            </div>
          </Link>
        </div>

        <div style={style.menuElement}>
          <Link to='/customer/map'>
            <div style={{padding: '0px 10px'}}>
              <div style={{border: active === 'map' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'map' ? '#ade0fd' : '#fff'}}>
                <img
                  style={{ marginTop: '0px', marginLeft: '0px' }}
                  alt='Logo'
                  className='h-55px'
                  src={toAbsoluteUrl(active === 'map' ? '/media/logos/menu31.png' : '/media/logos/menu32.png')}
                />
              </div>
            </div>
          </Link>
        </div>

        <div style={style.menuElement}>
          <Link to='/customer/health'>
            <div style={{padding: '0px 10px'}}>
              <div style={{border: active === 'health' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'health' ? '#ade0fd' : '#fff'}}>
                <img
                  style={{ marginTop: '0px', marginLeft: '0px' }}
                  alt='Logo'
                  className='h-55px'
                  src={toAbsoluteUrl(active === 'health' ? '/media/logos/menu41.png' : '/media/logos/menu42.png')}
                />
              </div>
            </div>
          </Link>
        </div>

      </div>
    </>
  )
}

export {MenuTwo}
