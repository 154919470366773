import React, {useState, useEffect} from 'react'
import axios from "axios";
import {BACKEND_URL} from "../redux/AuthCRUD";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

export function ForgotPassword() {
  const history = useHistory()

  const [isValidData, setIsValidData] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [scanCode, setScanCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [visiblePassword, setVisiblePassword] = useState(false)

  const handleConfirm = () => {
    setErrorMsg('')
    axios.post(`${BACKEND_URL}/change-password`, {
      phone: phoneNumber,
      new_password: newPassword,
      scan_code: scanCode,
    }).then(res => res.data)
      .then(resData => {
        if (resData.error_code === 1) {
          toast("Thay đổi mật khẩu thành công", {
            onClose: () => {
              history.push('/auth/login')
            }
          })
        } else {
          setErrorMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        }
      })
  }

  useEffect(() => {
    if (phoneNumber && scanCode && newPassword && confirmNewPassword && newPassword === confirmNewPassword && scanCode.length === 6) {
      setIsValidData(true)
    } else {
      setIsValidData(false)
    }
  }, [phoneNumber, scanCode, newPassword, confirmNewPassword])

  return (
    <>
      <ToastContainer />
      <div
        className='form w-100'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Quên mật khẩu</h1>
        </div>
        {/* begin::Heading */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Số điện thoại</label>
          <input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type='text'
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Mã quét mặt</label>
          <input
            value={scanCode}
            onChange={(e) => setScanCode(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type='text'
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Mật khẩu mới</label>
          <label
            onClick={() => setVisiblePassword(!visiblePassword)}
            style={{ cursor: 'pointer', float: 'right', color: 'blue' }}
          >
            {visiblePassword
              ?
              'Ẩn mật khẩu'
              :
              'Hiện mật khẩu'
            }
          </label>
          <input
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type={visiblePassword ? 'text' : 'password'}
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Xác nhận mật khẩu mới</label>
          <input
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            className='form-control form-control-lg form-control-solid'
            type={visiblePassword ? 'text' : 'password'}
            autoComplete='off'
          />
        </div>
        {/* end::Form group */}

        {errorMsg && (<h4 style={{ color: 'red' }} >{errorMsg}</h4>)}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            disabled={isValidData ? '' : 'disabled'}
            type='button'
            className='btn btn-lg btn-primary w-100 mb-5'
            onClick={() => handleConfirm()}
          >
            <span className='indicator-label'>Xác nhận</span>
          </button>
        </div>
        {/* end::Action */}
      </div>
    </>
  )
}
