/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useLocation} from "react-router-dom";
import {MenuTwo} from '../components/MenuTwo'
import {FullReport} from "../pages/FullReport";
import {Lv1Report} from "../pages/Lv1Report";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const CustomerReportImage = () => {
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)
  const characterId = currentUser.user.character_id;
  const minorCharacterId = currentUser.user.minor_character_id;
  const roadCharacterId = currentUser.user.road_character_id;
  const spiritLevel = currentUser.user.spirit_level;
  const isFirst = currentUser.user.is_first;
  const [showButton, setShowButton] = useState(true);
  const reportVideo = useRef()
  const [errorMessage, setErrorMessage] = useState('');

  // đã trả tiền được đại lý duyệt
  const isActive = currentUser.user.is_active;

  // đã quét mặt
  const isScan = currentUser.user.is_scan;

  const userInfo = useSelector(store => store.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <>
      {isActive && isScan
        ?
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#333333'
                }}
              >
                <img style={{ width: WIDTH, height: 'auto' }} src={toAbsoluteUrl('/media/character/secret.jpg')} alt=""/>
              </div>
            </div>
            {currentUser.user.level === 0 && <>
              <div style={{ backgroundColor: '#333', height: '100%'}}>
                <div
                  style={{
                    width: WIDTH,
                    height: '100%',
                    margin: 'auto',
                    backgroundColor: '#333333'
                  }}
                >
                  <div
                    style={{
                      margin: '10px',
                      padding: '10px',
                      //backgroundColor: '#fff',
                      borderRadius: '10px'
                    }}
                  >
                    <p style={{ color: '#ffd700' }} className='fs-2'>Vui lòng nâng cấp gói để xem báo cáo</p>
                  </div>

                  <Link to={{
                    pathname: '/customer/package',
                    state: { test: "hello" }
                  }}>
                    <div style={{ padding: '10px' }}>
                      <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#6ef54f' }}>
                        <h4 style={{ color: '#fff' }}>
                          Nâng cấp gói
                        </h4>
                      </div>
                    </div>
                  </Link>

                  <Link to={{
                    pathname: '/customer/info-change-password',
                    state: { test: "hello" }
                  }}>
                    <div style={{ padding: '10px' }}>
                      <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                        <h4 style={{ color: '#fff' }}>
                          {LANGUAGE === 'VI'
                            ? 'Đổi mật khẩu'
                            : 'Change Password'
                          }
                        </h4>
                      </div>
                    </div>
                  </Link>

                  <Link to={{
                    pathname: '/customer/info-change-name',
                    state: { test: "hello" }
                  }}>
                    <div style={{ padding: '10px' }}>
                      <div style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#ff9000' }}>
                        <h4 style={{ color: '#fff' }}>
                          {LANGUAGE === 'VI'
                            ? 'Đổi tên'
                            : 'Change Name'
                          }
                        </h4>
                      </div>
                    </div>
                  </Link>

                  <div style={{ padding: '10px' }}>
                    <div
                      onClick={() => dispatch(auth.actions.logout())}
                      style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                      <h4 style={{ color: '#fff' }}>
                        {LANGUAGE === 'VI'
                          ? 'Đăng xuất'
                          : 'Log out'
                        }
                      </h4>
                    </div>
                  </div>

                  {/*menu*/}
                  <MenuTwo active='report'/>
                </div>

              </div>
            </>}

            {(currentUser.user.level === 1) && <>
              <Lv1Report />
            </>}

            {(currentUser.user.level >= 2) && <>
              <FullReport />
            </>}
          </>
        :
          <>
            <div style={{ backgroundColor: '#333', height: '100%'}}>
              <div
                style={{
                  width: WIDTH,
                  height: '100%',
                  margin: 'auto',
                  backgroundColor: '#fff',
                  padding: '20px'
                }}
              >
                {/*<img style={{ width: '100%', height: window.innerHeight - 70}} src={toAbsoluteUrl('/media/logos/inactive-report3.jpg')} alt=""/>*/}

                <h2>Mời bạn liên hệ với cộng tác viên của Anbi qua số điện thoại <span className='text-danger'>{ currentUser.user.sale.note ? currentUser.user.sale.note : currentUser.user.sale.phone }</span> để được hỗ trợ mở khóa bản phân tích.</h2>

                <div style={{ padding: '10px', display: !isFirst ? 'block' : 'none' }}>
                  <div
                    onClick={() => dispatch(auth.actions.logout())}
                    style={{ textAlign: 'center', padding: '10px', borderRadius: '10px', backgroundColor: '#01a1ff' }}>
                    <h4 style={{ color: '#fff' }}>
                      {LANGUAGE === 'VI'
                        ? 'Đăng xuất'
                        : 'Log out'
                      }
                    </h4>
                  </div>
                </div>

                {/*height menu*/}
                <div style={{height: '70px', display: isFirst ? 'block' : 'none'}}></div>
                {/*menu*/}
                <div style={{
                  display: isFirst ? 'block' : 'none',
                  position: 'fixed',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: WIDTH,
                  height: '70px',
                  margin: 'auto'
                }}>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/report-image'>
                      <div style={{padding: '0px 13px'}}>
                        <div style={{border: '1px solid #70cfff', borderRadius: '10px', backgroundColor: '#ade0fd'}}>
                          <img
                            alt='Logo'
                            className='h-50px'
                            src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/list-study'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                      />
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/intro'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/3-gioi-thieu.png')}
                      />
                    </Link>
                  </div>
                  <div style={{
                    width: '25%',
                    height: '100%',
                    backgroundColor: '#fff',
                    display: 'inline-block',
                    textAlign: 'center',
                    paddingTop: '10px'
                  }}>
                    <Link to='/customer/info'>
                      <img
                        alt='Logo'
                        className='h-50px'
                        src={toAbsoluteUrl('/media/logos/4-tai-khoan.png')}
                      />
                    </Link>
                  </div>
                </div>
              </div>

            </div>
          </>
      }

    </>
  )
}

export {CustomerReportImage}
